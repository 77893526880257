import { useEffect, useState } from "react";

export const useIsIPhone = () => {
  const [isIPhone, setIsIPhone] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/iPhone/i.test(userAgent)) {
      setIsIPhone(true);
    }
  }, []);

  return isIPhone;
};
