import { useState } from "react";
import { GameContext } from "./GameContext";
import { initialGameContextData } from "@data";

export const GameContextProvider = ({ children }) => {
  const [state, setState] = useState(initialGameContextData);

  const updateState = (state) => {
    setState((prevState) => ({ ...prevState, ...state }));
  };

  const handleGameIsLoading = () => {
    if (window.parent) {
      window.parent.postMessage({ message: "GAME_IS_LOADING" }, "*");
    }
  };

  const handleGameIsLoaded = () => {
    updateState({ isLoaded: true });

    if (window.parent) {
      window.parent.postMessage({ message: "GAME_IS_LOADED" }, "*");
    }
  };

  const handleGameIsClosed = () => {
    updateState({ isLoaded: false });

    if (window.parent) {
      window.parent.postMessage({ message: "GAME_IS_CLOSED" }, "*");
    }
  };

  return (
    <GameContext.Provider
      value={{
        handleGameIsLoading,
        handleGameIsLoaded,
        handleGameIsClosed,
        ...state,
      }}
      children={children}
    />
  );
};
