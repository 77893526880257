export const gameKeysWithMobileSupport = [
  "the_fruits_galore",
  "the_panda_dance",
  "da_thug_life",
  "treasures_of_transylvania",
  "the_bank_heist",
  "cryptowars",
  "guacamole",
  "tag_city_tales",
  "female_boutique_de_fashion",
  "capymafia"
];
