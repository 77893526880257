import { Logo } from "@components";
import { memo } from "react";
import styles from "./ErrorHeader.module.css";

export const ErrorHeader = memo(() => {
  return (
    <div className={styles.errorHeaderContainer}>
      <div className={styles.logo}>
        <Logo />
      </div>
    </div>
  );
});
