import { useEffect, useState } from "react";
import { GamesListLayoutUtil } from "../utils";

export function useGamesList() {
  const defaultLayoutPattern = GamesListLayoutUtil.getPattern(window.innerWidth);
  const [layoutPattern, setLayoutPattern] = useState(defaultLayoutPattern);
  const [isMobileMode, setIsMobileMode] = useState(false);

  useEffect(() => {
    function handleResize() {
      const newLayoutPattern = GamesListLayoutUtil.getPattern(
        window.innerWidth
      );
      setLayoutPattern(newLayoutPattern);

      setIsMobileMode(window.innerWidth <= 960);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { layoutPattern, isMobileMode };
}
