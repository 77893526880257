import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Route, Routes } from "react-router-dom";
import { useAuthContext, useGamesListContext } from "./hooks";
import { GameLayout } from "./layouts/GameLayout/GameLayout";
import { MainLayout } from "./layouts/MainLayout/MainLayout";
import { Game, Home, NotFound } from "./pages";
import { GlobalStyle } from "./styles";

function App() {
  const { initAuthToken } = useAuthContext();
  const { addScrollHandler } = useGamesListContext();

  useEffect(() => {
    initAuthToken(window.location.search);
    addScrollHandler();
  }, []);

  return (
    <>
      <GlobalStyle />

      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/:type/:id" element={<GameLayout />}>
          <Route index element={<Game />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
