import { DEFAULT_CATEGORY } from "./constants";

export const initialGamesListContextData = {
  games: [],
  categories: [],
  selectedCategoryKey: DEFAULT_CATEGORY,
  selectedCategoryGames: [],
  searchValue: "",
  loading: true,
  error: null,
};
