import { memo } from "react";
import { Link } from "react-router-dom";
import { backArrow, cross } from "@assets";
import { useGameContext, useGamesList } from "@hooks";
import styles from "./BackButton.module.css";

export const BackButton = memo(({ linkPath, hasMobileSupport }) => {
  const { handleGameIsClosed } = useGameContext();
  const { isMobileMode } = useGamesList();

  const iconSrc = hasMobileSupport && isMobileMode ? cross : backArrow;
  const iconClass =
    hasMobileSupport && isMobileMode ? styles.cross : styles.arrow;
  const altText =
    hasMobileSupport && isMobileMode ? "Close button" : "Back arrow";
  const shouldShowButtonText = !hasMobileSupport || !isMobileMode;

  return (
    <Link
      to={linkPath}
      className={styles.backButton}
      onClick={handleGameIsClosed}
    >
      <img src={iconSrc} className={iconClass} alt={altText} />
      {shouldShowButtonText && <p>Back to games</p>}
    </Link>
  );
});
