import { useEffect } from "react";
import { Category, Loader, Error, GamesList } from "@components";
import { useGamesListContext } from "@hooks";
import { categoriesData, errorTypes } from "@data";

export const Home = () => {
  const {
    error,
    selectedCategoryGames,
    categories,
    updateGamesData,
    searchValue,
    games,
    loading,
  } = useGamesListContext();

  useEffect(() => {
    updateGamesData();
  }, []);

  const categoriesExtendedWithAll = () => {
    return [categoriesData.all.id, ...categories];
  };

  if (loading) return <Loader />;

  if (error) return <Error errorType={errorTypes.GAMES_LIST_FAILED_LOADING} />;

  const noSearchResult = searchValue && games.length === 0;

  return (
    <>
      {!searchValue && <Category categories={categoriesExtendedWithAll()} />}

      {noSearchResult && <Error errorType={errorTypes.NO_SEARCH_RESULT} />}

      <GamesList games={selectedCategoryGames} />
    </>
  );
};
