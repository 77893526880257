import styles from "./GameIFrame.module.css";
import { Loader } from "@components";
import { useGameContext, useGamesList } from "@hooks";
import { useEffect } from "react";

export const GameIFrame = ({ src, title }) => {
  const { isLoaded, handleGameIsLoaded, handleGameIsLoading } =
    useGameContext();
  const { isMobileMode } = useGamesList();

  const handleLoad = () => {
    handleGameIsLoaded();
  };

  useEffect(() => {
    handleGameIsLoading();
  }, []);

  return (
    <>
      {!isLoaded ? <Loader /> : null}
      <iframe
        className={`${styles.gameIFrame} ${isMobileMode && styles.mobile}`}
        style={{ visibility: !isLoaded ? "hidden" : "visible" }}
        src={src}
        allowFullScreen={true}
        title={title}
        onLoad={handleLoad}
      ></iframe>
    </>
  );
};
