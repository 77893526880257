import { Error, Loader, GameHeader } from "@components";
import { errorTypes, gameKeysWithMobileSupport } from "@data";
import { useGamesList, useGameContext, useIsIPhone } from "@hooks";
import { useOutletContext } from "react-router-dom";
import { GameBody, GameFooter } from "./children";
import styles from "./Game.module.css";

export const Game = () => {
  const { gameData, loading, error } = useOutletContext();
  const { isMobileMode } = useGamesList();
  const { isLoaded } = useGameContext();
  const isIPhone = useIsIPhone();

  if (loading) return <Loader />;

  if (error) return <Error errorType={errorTypes.GAME_NOT_AVAILABLE} />;

  if (!gameData) return null;

  const hasMobileSupport = gameKeysWithMobileSupport.includes(gameData.key);
  const shouldShowHeader =
    !isMobileMode || !hasMobileSupport || (hasMobileSupport && isLoaded);
  const shouldShowFooter =
    isLoaded && hasMobileSupport && isMobileMode && isIPhone;

  return (
    <div
      className={`${hasMobileSupport && isMobileMode ? styles.mobile : ""} ${
        isLoaded ? styles.loaded : ""
      }`}
    >
      {shouldShowHeader && (
        <GameHeader
          shouldShowHeader={shouldShowHeader}
          hasMobileSupport={hasMobileSupport}
          gameData={gameData}
        />
      )}
      <GameBody />
      {shouldShowFooter && <GameFooter />}
    </div>
  );
};
