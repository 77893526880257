import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "reset-css";
import "./assets/fonts/fonts.css";
import App from "./App";
import {
  AuthContextProvider,
  GameContextProvider,
  GamesListContextProvider,
} from "./context";

const app = (
  <AuthContextProvider>
    <GamesListContextProvider>
      <GameContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GameContextProvider>
    </GamesListContextProvider>
  </AuthContextProvider>
);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<React.StrictMode>{app}</React.StrictMode>);

/** @TODO Not sure if we need this */
// reportWebVitals();
