import { BackButton, Logo } from "@components";
import { useGamesList } from "@hooks";
import { memo } from "react";
import {
  default as gameHeaderStyles,
  default as styles,
} from "./GameHeader.module.css";

export const GameHeader = memo(({ gameData, hasMobileSupport }) => {
  const gameTitle = gameData ? gameData.title : null;
  const { isMobileMode } = useGamesList();

  return (
    <div
      className={`${styles.gameHeaderContainer} ${
        hasMobileSupport && isMobileMode && styles.mobile
      }`}
    >
      {(!hasMobileSupport || !isMobileMode) && (
        <>
          <div className={styles.logo}>
            <Logo />
          </div>
          {gameTitle ? (
            <p className={gameHeaderStyles.gameHeaderTitle}>{gameTitle}</p>
          ) : null}
        </>
      )}

      <BackButton hasMobileSupport={hasMobileSupport} linkPath="/" />
    </div>
  );
});
